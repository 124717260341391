<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import Multiselect from "vue-multiselect";
// import CKEditor from "@ckeditor/ckeditor5-vue";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
//import router from "@/router/index.js";
// import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "LIEUX DE REFERENCES",
      items: [
        {
          text: "TAXI",
        },
        {
          text: "REFERENCES",
          active: true,
        },
      ],
      userChargeLoader: false,

      totalRows: 1,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      fields: [
        { key: "addAt", sortable: true, label: "Ajouté le" },
        { key: "lieu", sortable: true, label: "Lieu" },
        { key: "service", sortable: true, label: "Service" },
        { key: "lieuLng", sortable: true, label: "Longitude" },
        { key: "lieuLat", sortable: true, label: "Latitude" },
        { key: "action", label: "Actions" },
      ],
      lieu: {
        id:null,
        nom: '',
        longitude: null,
        latitude: null,
        service:''
      },
      referenceModal: false,
      allPlaces: [],
      editMode: false,
      placeId: null,


    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.dataUser.length;
    // },
    // rowsinactif() {
    //   return this.dataUserD.length;
    // },
  },
  async mounted() {
    this.init();

  },
  methods: {

    async addReference() {

      if(!this.lieu.service){
        this.$toast.error('Choisissez le service associé');
        return
      }

      if (!this.editMode) {

        try {

          const response = await apiRequest('POST', 'nouvelle-ref', { lieu: this.lieu , service:this.lieu.service });
          if (response && response.status == 200) {
            this.$toast.success('Nouveau lieu ajouté avec succès');
            this.init()

          }
        } catch (error) {

          this.$toast.error(error.message)

        }

      } else {


        try {

          const response = await apiRequest('PUT', 'update-ref', { id: this.placeId, lieu: this.lieu , service:this.lieu.service });
          if (response && response.status == 200) {
            this.$toast.success('Lieu Modifié avec succès');
            this.referenceModal = false;
            this.init()

          }
        } catch (error) {

          this.$toast.error(error.message)

        }

      }



    }
    ,

    setPlaceData: function (place) {

      if (place) {
        console.log(place);
        this.lieu.nom = place.name;
        this.lieu.longitude = place.geometry.location.lng();
        this.lieu.latitude = place.geometry.location.lat();
      }
    },
    async init() {
      this.allPlaces = []
      const response = await apiRequest("GET", "all-ref", undefined, false);
      console.log("response", response)
      if (response && response.data) {
        response.data.lieux_ref.sort((a, b) => b.id - a.id).forEach(data => {
          this.allPlaces.push(data);
        })

      }
    },

    handleDelete(place) {
      this.placeId = place.id;
      this.showMsgBoxTwo();
    },

    showMsgBoxTwo() {
      this.boxTwo = ''
      this.$bvModal.msgBoxConfirm('Voulez-vous supprimer ce lieu ?', {
        title: 'Confirmez votre action',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'OUI',
        cancelTitle: 'NON',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          this.boxTwo = value
          if (value) {

            const response = await apiRequest('DELETE', 'remove-ref', { id: this.placeId })

            if (response && response.status == 200) {
              this.$toast.success('Lieu supprimé avec succès');
              this.init();
              this.hideModal();
            }

          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
    },

    handleEdit(place) {

      this.lieu = {
        longitude: place.lieuLng,
        latitude: place.lieuLat,
        nom: place.lieu,
        service:place.service

      }
      this.editMode = true;
      this.placeId = place.id;
      this.referenceModal = true;
    },
    hideModal() {

      this.editMode = false;
      this.placeId = null;

    }

  },

  watch: {

  },
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">


      <div class="col-lg-12">

        <div class="card homeContainer">

          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>

          <div class="card-body pt-0">

            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Total :0
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <div class="col-md-3">
                    <a href="javascript:void(0)" class="btn btn-success" @click="referenceModal = true"> Ajouter</a>
                  </div>
                  <!-- Search -->
                  <div class=" col-md-4 d-flex align-items-center  justify-content-center">
                    <div class="inputCol">


                      <div class="input mb-2">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->

                  <div class="col-md-4  d-flex justify-content-center  align-items-center">
                    



                      <b-pagination v-model="currentPage" :total-rows="allPlaces.length" :per-page="perPage" :limit="2"
                        first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination>

                      <span class="d-block mt-2 mx-2 pb-3 fw-bold fs-2 text-nowrap"> 
                        {{ currentPage }} sur {{ Math.ceil(allPlaces.length /perPage) }}
                          </span>


                    
                  </div>
                </div>
                <div class="table-responsive mt-2">



                  <b-table id="trajet" style="cursor: pointer" class="table-centered" :items="allPlaces"
                    :fields="fields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :per-page="perPage" :current-page="currentPage">


                    <template v-slot:cell(addAt)="row">

                      <div>
                        {{ new Date(row.value).toLocaleDateString('FR-fr') }}
                      </div>

                    </template>
                    <template v-slot:cell(action)="row">
                      <div class="row">

                        <div class=" col-6 text-warning" @click="handleEdit(row.item)">
                          <i class="fas fa-pen "></i>
                        </div>

                        <div class=" col-6 text-danger" @click="handleDelete(row.item)">
                          <i class="fas fa-trash "></i>
                        </div>

                      </div>


                    </template>

                  </b-table>
                </div>
                <div class="row">
                  <div class="col">

                    <!-- <div class="d-flex justify-content-end align-tems-center">
                      <span class="d-block mt-2 mx-2"> {{ currentPage }} sur {{ (newDataUser.length) != 0 ?
      Math.ceil(newDataUser.length /
        perPage) : Math.ceil(dataUser.length / perPage) }}</span>


                      <b-pagination v-model="currentPage"
                        :total-rows="newDataUser.length != 0 ? newDataUser.length : dataUser.length" :per-page="perPage"
                        :limit="2" first-text="◀" last-text="▶" aria-controls="my-table">
                      </b-pagination>

                    </div> -->
                  </div>
                </div>

              </b-tab>



            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-center" centered size="md" v-model="referenceModal" @hidden="hideModal"
      title="Ajouter un lieu de ref." title-class="font-18" hide-footer>
      <form enctype="multipart/form-data" @submit.prevent="addReference">
        <div class="row ">
          <div class="col-md-6 col-xl-6 mb-3 form-group">
            <label for="lieu">Lieu de réf.</label>
            <gmap-autocomplete type="text" :value="editMode === true ? lieu.nom : ''" class="form-control" id="lieu"
              placeholder="Tapez la réf." @place_changed="setPlaceData" :select-first-on-enter="true"
              style="height: 50px">
            </gmap-autocomplete>
          </div>
          <div class="col-md-6 form-group" >
            <label for="serv">Service</label>
            <select v-model="lieu.service" id="serv" class="form-control">
              <option value="" disabled>Select. un lieu</option>
              <option value="LOCATION" :selected="lieu.service === 'LOCATION'">LOCATION</option>
              <option value="VTC" :selected="lieu.service === 'VTC'">VTC</option>
              <option value="VTCM" :selected="lieu.service === 'VTCM'">VTCM</option>
              <option value="COVOITURAGE" :selected="lieu.service === 'COVOITURAGE'">COVOITURAGE</option>
            </select>
          </div>
          <div class="form-group pl-3">

            <button type="submit" class="btn btn-primary">
              {{ editMode === true ? 'Modifier' : 'Ajouter' }}
            </button>
          </div>
        </div>

      </form>
    </b-modal>

    <!-- ENVOYER LE MESSAGE NOTIF-->

    <!-- -->
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

thead th:first-child {
  font-weight: 600 !important;
}

thead th span {
  color: #fff;
}

.b-pagination .active .page-link {
  background-color: #3AAA3A !important;
}

.hover-gray:hover {
  background-color: #cccccc3d;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

/*table filter design*/

.input {

  width: 300px;
  height: 35px;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}

.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

.inputCol {
  position: relative;
  float: right;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

.pagination ul {
  position: relative;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.myIcon {
  margin-top: 2px;
}

.loading__dots {
  display: inline-flex;
  column-gap: 0.25rem;
}

.loading__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
}

.loading__dot:nth-child(1) {
  position: absolute;
  transform: scale(0);
}

.loading__dot:nth-child(4) {
  background: transparent;
}

.loading__dot-down {
  display: block;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
  transform: translate(0);
}

.animate .loading__dot:nth-child(1) {
  animation: scale-dot 0.8s 0.2s forwards;
}

.animate .loading__dot:nth-child(2),
.animate .loading__dot:nth-child(3) {
  animation: move-right 0.6s forwards;
}

.animate .loading__dot-down {
  animation: move-right-down 0.8s 0.2s forwards linear,
    move-down 2.8s 0.3s forwards ease-in;
}

@keyframes scale-dot {
  100% {
    transform: scale(1);
  }
}

@keyframes move-right {
  100% {
    transform: translateX (0.75rem);
  }
}

@keyframes move-right-down {
  50% {
    transform: translate(1.5rem, 0.25rem);
  }

  100% {
    transform: translate(2rem, 0.5rem);
  }
}

@keyframes move-down {
  100% {
    transform: translate(10rem, 80vh);
  }
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.homeContainer {
  position: relative;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

/** Conducteur option */

.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}

.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}

.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}


.multiselect__tags {
  min-height: 50px !important;
  height: 50px;
}
</style>
